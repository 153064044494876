import { useQuery } from 'react-query'
import useApi from '../../API/useApi'

export enum RoomPermissionsTypes {
  CHAT_DELETE = 'room:chat:delete',
  CHAT_DOWNLOAD = 'room:chat:download',
  CHAT_LIST = 'room:chat:list',
  CHAT_WRITE = 'room:chat:write',
  DELETE = 'room:delete',
  INVITE_CREATE = 'room:invite:create',
  INVITE_DELETE = 'room:invite:delete',
  INVITE_LIST = 'room:invite:list',
  NOTE_DELETE = 'room:note:delete',
  NOTE_DOWNLOAD = 'room:note:download',
  NOTE_LIST = 'room:note:list',
  NOTE_WRITE = 'room:note:write',
  SESSION_CREATE = 'room:session:create', // recording session
  SESSION_DELETE = 'room:session:delete', // recording session
  SESSION_LIST = 'room:session:list',
  SESSION_WATCH = 'room:session:watch',
  UPDATE = 'room:update',
  ROLE_ASSIGN = 'room:role:assign',
  ROLE_UNASSIGN = 'room:role:unassign',
  MUTE_ALL = 'room:mute_all',
  KICK = 'room:kick',
  DO_NOT_DISTURB = 'room:dnd',
}

const useRoomPermissionsRest = (roomId) => {
  const { Permission } = useApi()
  const { data: permissionsObject } = useQuery('get-room-permissions', () =>
    Permission.getRoomPermissions(roomId)
  )

  const hasPermission = (permission: RoomPermissionsTypes) => {
    if (!permissionsObject) return false
    return permissionsObject[permission]?.rooms.includes(roomId) ?? false
  }

  const hasRecordingPermissions = hasPermission(RoomPermissionsTypes.SESSION_CREATE)
  const hasMuteAllPermissions = hasPermission(RoomPermissionsTypes.MUTE_ALL)
  const hasKickPermissions = hasPermission(RoomPermissionsTypes.KICK)
  const hasDoNotDisturbPermissions = hasPermission(RoomPermissionsTypes.DO_NOT_DISTURB)
  const hasRoomEdittingPermissions = hasPermission(RoomPermissionsTypes.UPDATE)
  const hasChatDownloadPermissions = hasPermission(RoomPermissionsTypes.CHAT_DOWNLOAD)

  return { hasPermission, hasRecordingPermissions, hasMuteAllPermissions, hasKickPermissions, hasDoNotDisturbPermissions, hasRoomEdittingPermissions, hasChatDownloadPermissions }
}

export default useRoomPermissionsRest
