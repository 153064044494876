import { forEachObjIndexed, pickBy, last, pathOr } from 'ramda'
import { v4 as uuid } from 'uuid'
import createOrGetLCInstance from './lcanvas'
import { ConditionalLoggerAdapter } from '../../Common/log/ConditionalLoggerAdapter'
import { ParticipantType } from '../janus/clients/liveroom/LiveroomClient'

const ratiosMap = new Map([
  [1280 / 960, { w: 1280, h: 960 }], // 4:3
  [1280 / 720, { w: 1280, h: 720 }], // 16:9
  [1280 / 800, { w: 1280, h: 800 }], // 16:10
  [2560 / 1080, { w: 2560, h: 1080 }], // 21:9
])

const defaultWidth = 1280
const defaultHeight = 720

// TODO potentially move these classes to a web worker
class DrawCanvasQueueSnapshotItem {
  constructor(snapshot, canvas) {
    this.methodName = 'loadSnapshot'
    this.snapshot = snapshot
    this.canvas = canvas
  }

  getPayload() {
    return this.snapshot
  }
}

class DrawCanvasQueueShapeItem {
  constructor(shape, canvas) {
    this.methodName = 'loadShape'
    this.shape = shape
    this.canvas = canvas
  }

  getPayload() {
    return this.shape
  }
}

class DrawCanvasQueue {
  constructor(bufferLc) {
    this.bufferLc = bufferLc
    this.queue = []
  }

  loadBufferLc(bufferLc) {
    if (!this.bufferLc) {
      this.bufferLc = bufferLc
    }
  }

  enqueueSnapshotToCanvasJob(snapshot, canvas) {
    const item = new DrawCanvasQueueSnapshotItem(snapshot, canvas)
    this.queue.push(item)
    this.next()
  }

  enqueueShapeToCanvasJob(shape, canvas) {
    const item = new DrawCanvasQueueShapeItem(shape, canvas)
    this.queue.push(item)
    this.next()
  }

  next() {
    if (!this.bufferLc || this.bufferLc.ctx) {
      return
    }
    Promise.resolve().then(() => {
      try {
        const item = this.queue.shift()
        if (!item || !item.canvas) {
          return
        }
        ConditionalLoggerAdapter.info(`Drawing to canvas with ${item.methodName}`, item)
        this.bufferLc.ctx = item.canvas.getContext('2d')
        this.bufferLc[item.methodName](item.getPayload())
        this.bufferLc.ctx = null
        this.next()
      } catch (e) {
        ConditionalLoggerAdapter.error(e, this.queue)
      }
    })
  }
}

/**
 * Type defining all possible messages handled by DrawCanvas
 * @type {{SnapshotRecording: string, DrawCanvasBroadcast: string}}
 */
export const DrawCanvasMessage = {
  DrawCanvasBroadcast: 'draw-canvas-broadcast',
  SnapshotRecording: 'snapshot-recording',
}

/**
 * Type defining DrawCanvas mode.
 * In Liveroom mode DrawCanvas responds to 'draw_snapshot' message by broadcasting all currently stored snapshots.
 * In Playback mode DrawCanvas handles `snapshot-recording` and displays all snapshots included in the message.
 * @type {{Playback: number, Liveroom: number}}
 */
export const DrawCanvasMode = {
  Liveroom: 1,
  Playback: 2,
}

/**
 * Class responsible for holding draw tool logic
 * Holds responsibility for replicating, listening and applying drawings across PeerConnections
 */
export class DrawCanvas {
  /**
   * DrawCanvas Constructor
   * @param callbacks
   * {
   *    canvasCreated: () => {} // Callback for when the main LiterallyCanvas instance finishes initializing
   * }
   * @param config
   * {
   *    drawCanvasMode: Liveroom or Playback // Indicates where DrawCanvas is used
   * }
   */
  constructor(
    callbacks = {},
    config = {
      drawCanvasMode: DrawCanvasMode.Liveroom,
    }
  ) {
    ConditionalLoggerAdapter.info(`Creating DrawCanvas with config: ${JSON.stringify(config)}`)
    this.LC = createOrGetLCInstance()
    /*
     * DrawCanvas can be used in both Liveroom and Playback.
     * In Liveroom we have to discard snapshot-recording data channel messages.
     * In Playback we have to discard draw-canvas-broadcast data channel messages.
     */
    this.drawCanvasMode = config.drawCanvasMode
    /**
     * When draw recording is enabled draw canvas will send snapshot-recording message
     * whenever janus requests it by sending draw_snapshot
     */
    this.publisher = null
    this.remoteParticipants = []
    this.currentVideoSizeObj = null
    this.callbacks = callbacks
    this.remoteCanvasLayersMap = {} // DOM references for all remote peers canvas elements
    this.sendData = null // Main datachannel send function provided by janus.js callback
    this.remoteChannels = {} // All remote peers subscriptions
    this.drawCanvasQueue = new DrawCanvasQueue()
    this.activeStreamId = null // Id to identify stream to which drawings will be applied
    /* Map of maps for streams and their layers/snapshots
     * [streamId, Map: {
     *   participantId1: snapshot
     *   participantId2: snapshot
     *   participantId3: snapshot
     * }]
     * ...
     */
    this.participantsSnapshotsMap = new Map()
    /*
     * Map for all our local drawings
     * [streamId, snapshot]
     * ...
     */
    this.localSnapshotMap = new Map()
    /*
     * References for DOM elements in the thumbnail area
     * {
     *   [participantId]: {
     *     canvas // Canvas element reference
     *     video // Video element reference
     *   }
     * }
     */
    this.thumbnailsRefs = {}
    this.thumbnailsDrawingTimeouts = {}
  }

  teardown() {
    if (this.videoDrawingTimeout) {
      clearTimeout(this.videoDrawingTimeout)
    }
    if (this.lc) {
      this.lc.teardown()
      this.lc = null
    }
    if (this.bufferLc) {
      this.bufferLc.teardown()
      this.bufferLc = null
    }
    Object.values(this.remoteChannels).forEach((subscription) => {
      subscription.cleanup()
    })
  }

  /**
   * Helper method to find ideal resolution for canvas based on video aspect ratio
   * @param videoWidth Video stream innerWidth
   * @param videoHeight Video stream innerHeight
   * @returns {*}
   */
  static getNewCanvasesSize(videoWidth = defaultWidth, videoHeight = defaultHeight) {
    ConditionalLoggerAdapter.info('Getting new canvas size', videoWidth, videoHeight, ratiosMap)
    const ratio = (videoWidth || defaultWidth) / (videoHeight || defaultHeight)
    ConditionalLoggerAdapter.info('Video aspect ratio is', ratio)
    const preDefinedResolution = ratiosMap.get(ratio)
    if (preDefinedResolution) {
      return preDefinedResolution
    }
    let closestRatio = Infinity
    for (const [ratioKey] of ratiosMap.entries()) {
      const diff = Math.abs(ratioKey - ratio)
      if (diff < closestRatio) {
        closestRatio = ratioKey
      }
    }
    if (closestRatio === Infinity) {
      ConditionalLoggerAdapter.error('Infinite difference', { videoWidth, videoHeight, ratiosMap })
      closestRatio = defaultWidth / defaultHeight // Defaults to 16:9
    }
    const closestSize = ratiosMap.get(closestRatio)
    return {
      h: closestSize.h,
      w: closestSize.h * ratio,
    }
  }

  /*
   * Helper method to modify a single canvas size
   */
  static modifyCanvasSize(canvas, newWidth, newHeight) {
    if (!canvas) {
      return
    }
    if (canvas.width !== newWidth) {
      canvas.width = newWidth
    }
    if (canvas.height !== newHeight) {
      canvas.height = newHeight
    }
  }

  static getHeaderFromSnapshot(snapshot) {
    return pickBy((val, key) => key !== 'shapes', snapshot)
  }

  /**
   * Check new array of remote participants for ones that are not present anymore and clear data accordingly
   * @param remoteParticipants Remote participants array from rxJanus
   */
  handleStaleParticipantCanvas(remoteParticipants = []) {
    this.remoteParticipants.forEach((remoteParticipant) => {
      const isStale = !remoteParticipants.find(
        (participant) => participant.id === remoteParticipant.id
      )
      if (isStale) {
        this.teardownParticipant(remoteParticipant)
      }
    })
  }

  /**
   * Remove participants from canvas playback
   * @param remoteParticipants Remote participants array from rxJanus
   */
  removeCanvasParticipants(remoteParticipants = []) {
    this.remoteParticipants.forEach((remoteParticipant) => {
      this.teardownParticipant(remoteParticipant)
    })
  }

  /**
   * Checks whether handling given message type is handled in current mode.
   * In Liveroom mode we're only handling DrawCanvasBroadcast messages (created and broadcasted when someone draws)
   * In Playback mode we're handling both DrawCanvasBroadcast and SnapshotRecording messages sent by Janus every keyframe.
   * @param messageType {string}
   * @returns {boolean|boolean}
   */
  isHandlingMessageTypeSupported(messageType) {
    const isPlaybackOrLiveroom =
      this.drawCanvasMode === DrawCanvasMode.Liveroom ||
      this.drawCanvasMode === DrawCanvasMode.Playback
    if (isPlaybackOrLiveroom && messageType === DrawCanvasMessage.DrawCanvasBroadcast) {
      return true
    }

    return (
      this.drawCanvasMode === DrawCanvasMode.Playback &&
      messageType === DrawCanvasMessage.SnapshotRecording
    )
  }

  /**
   * Main replication entrance
   * We receive snapshots then handle it accordingly
   * @param participant Message sender
   * @param event Event received in the datachannel listener
   */
  handleParticipantMessage(participant, event) {
    ConditionalLoggerAdapter.info('Participant message received', participant.id, event.data)

    try {
      const { streamId, type, message } = JSON.parse(event.data)
      if (!streamId) {
        ConditionalLoggerAdapter.info('No stream id on draw message')
      }
      if (!this.isHandlingMessageTypeSupported(type)) {
        ConditionalLoggerAdapter.info(`Handling message: ${type} is not supported, skipping`)
        return
      }

      const isClearCommand =
        pathOr(null, ['shapes', 'length'], message) === 0 || pathOr(false, ['clearCanvas'], message)
      const shouldUpdateMainVideoCanvas =
        streamId === this.activeStreamId && this.remoteCanvasLayersMap[participant.id]
      let newThumbnailShape = null
      // Get existing obj stored locally
      let localSnapshot
      if (this.participantsSnapshotsMap.has(streamId)) {
        localSnapshot = this.participantsSnapshotsMap.get(streamId).get(participant.id)
      }
      const shouldUpdateLocalSnapshotRecord = !localSnapshot || isClearCommand
      if (shouldUpdateLocalSnapshotRecord) {
        localSnapshot = message
        if (shouldUpdateMainVideoCanvas) {
          this.drawCanvasQueue.enqueueSnapshotToCanvasJob(
            localSnapshot,
            this.remoteCanvasLayersMap[participant.id]
          )
        }
      } else {
        const newShape = message.shapes[0]
        // TODO find way to broadcast new canvas just to new peers
        // Reason we're treating dupes is because dc.send is a broadcast kind of message
        const isDuplicated = localSnapshot.shapes.find((shape) => shape.controlId === newShape)
        if (isDuplicated) {
          return
        }
        newThumbnailShape = message.shapes[0]
        localSnapshot.shapes.push(newShape)
        if (shouldUpdateMainVideoCanvas) {
          this.drawCanvasQueue.enqueueShapeToCanvasJob(
            newShape,
            this.remoteCanvasLayersMap[participant.id]
          )
        }
      }

      // Store updated snapshot in local structure
      if (this.participantsSnapshotsMap.has(streamId)) {
        this.participantsSnapshotsMap.get(streamId).set(participant.id, localSnapshot)
      } else {
        this.participantsSnapshotsMap.set(streamId, new Map([[participant.id, localSnapshot]]))
      }

      // We always want to update the thumbnails with new drawings
      this.updateThumbnailCanvas(streamId, newThumbnailShape)
    } catch (e) {
      ConditionalLoggerAdapter.error(e, { participant, message: event.message })
    }
  }

  /**
   * Removes all data stored from a particular participant
   * @param participant Participant object
   */
  teardownParticipant(participant) {
    try {
      if (this.thumbnailsDrawingTimeouts[participant.id]) {
        clearTimeout(this.thumbnailsDrawingTimeouts[participant.id])
      }
      ConditionalLoggerAdapter.info('Remote connection closed', participant.id)

      // This will only be necessary for participants that could be subscribed to remote data channel events
      if (this.drawCanvasMode === DrawCanvasMode.Liveroom && this.remoteChannels[participant.id]) {
        this.remoteChannels[participant.id].cleanup()
      }

      delete this.remoteCanvasLayersMap[participant.id]
      delete this.remoteChannels[participant.id]
      // Remove remote users stored snapshots
      const thumbnailStreamsToUpdate = []
      for (const [key, map] of this.participantsSnapshotsMap.entries()) {
        if (map.has(participant.id)) {
          thumbnailStreamsToUpdate.push(key)
          map.delete(participant.id)
        }
      }
      this.participantsSnapshotsMap.delete(participant.id)
      this.localSnapshotMap.delete(participant.id)
      this.thumbnailsRefs[participant.id] = null
      delete this.thumbnailsRefs[participant.id]
      // Update all thumbnails
      // Apply values stored from remote peers
      thumbnailStreamsToUpdate.forEach((key) => {
        this.updateThumbnailCanvas(key)
      })
    } catch (e) {
      ConditionalLoggerAdapter.error(e, { participant })
    }
  }

  setActiveStream(activeStreamId) {
    if (this.activeStreamId !== activeStreamId) {
      this.activeStreamId = activeStreamId
      this.clearAllCanvases()
    }
  }

  // Canvas manipulation methods
  /*
   * Clears all main stream layers
   */
  clearAllCanvases() {
    this.lc.clear(false)
    ConditionalLoggerAdapter.info('Clearing all canvases', this.remoteCanvasLayersMap)
    forEachObjIndexed((canvas) => {
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }, this.remoteCanvasLayersMap)
  }

  /**
   * Updates all main stream layers with snapshots stored in the local map and participants map
   */
  updateCanvases() {
    // Apply local values if any
    const localSnap = this.localSnapshotMap.get(this.activeStreamId)
    if (localSnap) {
      this.lc.loadSnapshot(localSnap)
    }
    if (!this.participantsSnapshotsMap.has(this.activeStreamId)) {
      return
    }
    // Apply values stored from remote peers
    const map = this.participantsSnapshotsMap.get(this.activeStreamId)
    for (const [key, snapshot] of map.entries()) {
      this.drawCanvasQueue.enqueueSnapshotToCanvasJob(snapshot, this.remoteCanvasLayersMap[key])
    }
  }

  /**
   * Updates a single thumbnail canvas with all participants layers
   * All snapshots are merged in a single one then applied
   * @param streamId
   * @param newShape
   */
  updateThumbnailCanvas(streamId, newShape) {
    if (!this.thumbnailsRefs[streamId]) {
      return
    }
    const thumbnailCanvas = this.thumbnailsRefs[streamId].canvas
    if (newShape) {
      ConditionalLoggerAdapter.info(
        'Update thumbnail by shape',
        thumbnailCanvas,
        newShape,
        this.localSnapshotMap,
        this.participantsSnapshotsMap
      )
      return this.drawCanvasQueue.enqueueShapeToCanvasJob(newShape, thumbnailCanvas)
    }
    const ctx = thumbnailCanvas.getContext('2d')
    ctx.clearRect(0, 0, thumbnailCanvas.width, thumbnailCanvas.height)
    ctx.save()
    let shapes = []
    let baseSnapshot = null
    const localSnapshot = this.localSnapshotMap.get(streamId)
    if (localSnapshot) {
      baseSnapshot = localSnapshot
      shapes = shapes.concat(localSnapshot.shapes)
    }
    if (this.participantsSnapshotsMap.has(streamId)) {
      const map = this.participantsSnapshotsMap.get(streamId)
      for (const snapshot of map.values()) {
        // Local snapshot may be empty
        shapes = shapes.concat(snapshot.shapes)
        if (!baseSnapshot) {
          baseSnapshot = snapshot
        }
      }
    }
    if (!baseSnapshot) {
      return
    }
    const mergedSnapshot = { ...localSnapshot, shapes: [] }
    ConditionalLoggerAdapter.info(
      'Update thumbnail',
      mergedSnapshot,
      shapes,
      thumbnailCanvas,
      this.localSnapshotMap,
      this.participantsSnapshotsMap
    )
    this.drawCanvasQueue.enqueueSnapshotToCanvasJob(mergedSnapshot, thumbnailCanvas)
    // Just draw each shape individually in a non blocking way
    shapes.forEach((shape) => {
      return this.drawCanvasQueue.enqueueShapeToCanvasJob(shape, thumbnailCanvas)
    })
  }

  prepareCanvasSnapshot() {
    const snapshot = this.lc.getSnapshot()
    // This will greatly reduce message size but theres still a 64k length limit for the message
    snapshot.shapes.forEach((shape, index) => {
      if (!shape.controlId) {
        shape.controlId = uuid()
      }
      if (shape.data.smoothedPointCoordinatePairs) {
        delete snapshot.shapes[index].data.smoothedPointCoordinatePairs
      }
    })
    return snapshot
  }

  /**
   * Sends a snapshot through the local dataChannel send method
   * @param messageType
   * @param snapshot LiterallyCanvas snapshot
   * @param _streamId string
   */
  broadcastCanvasSnapshot(messageType, snapshot, _streamId) {
    if (!this.sendData || !this.lc) {
      return
    }

    try {
      const streamId = _streamId || this.activeStreamId
      const header = DrawCanvas.getHeaderFromSnapshot(snapshot)
      const shapes = snapshot.shapes || []
      const messages = []
      shapes.forEach((shape) => {
        const shapeMessage = {
          streamId,
          type: messageType,
          message: { ...header, shapes: [shape] },
        }
        messages.push(shapeMessage)
      })
      if (!messages.length && !shapes.length) {
        messages.push({
          streamId,
          type: messageType,
          message: { ...header, shapes: [] },
        })
      }
      messages.forEach((message) => {
        const stringifiedMessage = JSON.stringify(message)
        this.sendData({ text: stringifiedMessage })
      })
    } catch (e) {
      ConditionalLoggerAdapter.error(e)
    }
  }

  /**
   * Broadcasts the entire local snapshot. Used when a new participant enters or when recording starts.
   */
  broadcastLocalSnapshotMap() {
    for (const [key, snapshot] of this.localSnapshotMap.entries())
      this.broadcastCanvasSnapshot(DrawCanvasMessage.DrawCanvasBroadcast, snapshot, key)
  }

  broadcastLatestShape(snapshot, streamId) {
    if (!this.sendData || !this.lc) {
      return
    }
    try {
      const header = DrawCanvas.getHeaderFromSnapshot(snapshot)
      const message = {
        type: DrawCanvasMessage.DrawCanvasBroadcast,
        streamId: streamId || this.activeStreamId,
        message: { ...header, shapes: [last(snapshot.shapes)] },
      }
      const stringifiedMessage = JSON.stringify(message)
      this.sendData({ text: stringifiedMessage })
    } catch (e) {
      ConditionalLoggerAdapter.error(e)
    }
  }

  /**
   * Creates the LiterallyCanvas instance used for local drawing
   * @param node div element reference to attach our LC instance
   */
  createMainCanvas(node) {
    if (!node || this.lc) {
      return
    }
    this.canvasNode = node
    this.lc = this.LC.init(this.canvasNode, {
      primaryColor: '#FFFF00',
    })
    if (this.callbacks.canvasCreated) {
      this.callbacks.canvasCreated()
    }
    // Attach events
    // Triggered every time the mouseup event is triggered while a drawing is in progress
    // TODO create better methods for all events
    // TODO attach callbacks to every event to allow extension from outside
    this.lc.on('shapeSave', () => {
      const snapshot = this.prepareCanvasSnapshot()
      // Save on our local structure
      this.localSnapshotMap.set(this.activeStreamId, snapshot)
      this.broadcastLatestShape(snapshot)
      this.updateThumbnailCanvas(this.activeStreamId, last(snapshot.shapes))
    })

    this.lc.on('clear', () => {
      const snapshot = this.prepareCanvasSnapshot()
      // Save on our local structure
      this.localSnapshotMap.set(this.activeStreamId, snapshot)
      this.broadcastCanvasSnapshot(DrawCanvasMessage.DrawCanvasBroadcast, snapshot)
      this.updateThumbnailCanvas(this.activeStreamId)
    })
  }

  /**
   * Creates the LiterallyCanvas instance used for drawing on remote participant canvases
   * @param node div element reference to attach our LC instance
   */
  createBufferCanvas(node) {
    if (!node || this.bufferNode) {
      return
    }
    this.bufferNode = node
    this.bufferLc = this.LC.init(this.bufferNode)
    this.drawCanvasQueue.loadBufferLc(this.bufferLc)
    this.bufferNode.style.setProperty('pointer-events', 'none')
    this.bufferLc.ctx = null
  }

  /**
   * Updates all main stream layers with new found size from video metadata callback
   * @param videoWidth Video stream innerWidth
   * @param videoHeight Video stream innerHeight
   */
  adjustCanvasesSizes(videoWidth, videoHeight) {
    const newSize = DrawCanvas.getNewCanvasesSize(videoWidth, videoHeight)
    ConditionalLoggerAdapter.info('Received new size for main stream', newSize)
    if (newSize) {
      // Update all layers
      const { w, h } = newSize
      DrawCanvas.modifyCanvasSize(this.lc.canvas, w, h)
      DrawCanvas.modifyCanvasSize(this.lc.buffer, w, h)
      DrawCanvas.modifyCanvasSize(this.bufferLc.canvas, w, h)
      forEachObjIndexed((canvas) => {
        DrawCanvas.modifyCanvasSize(canvas, w, h)
      }, this.remoteCanvasLayersMap)
    }
  }

  // Set node methods for all required elements
  // TODO potentially create specific class for these actions
  setLocalCanvasNode(node, type) {
    if (!node || !document.body.contains(node)) {
      return
    }
    switch (type) {
      case 'main':
        this.createMainCanvas(node)
        break
      case 'buffer':
        this.createBufferCanvas(node)
        break
      default:
        this.createMainCanvas(node)
    }
  }

  setParticipantCanvasNode(participant, index, node) {
    ConditionalLoggerAdapter.info(
      'Got participant canvas node',
      participant,
      node,
      this.remoteCanvasLayersMap,
      this.remoteCanvasLayersMap[participant.id]
    )
    if (!node || !document.body.contains(node)) {
      return
    }

    const existingNode = this.remoteCanvasLayersMap[participant.id]
    if (existingNode && existingNode.isSameNode(node)) {
      return
    }

    ConditionalLoggerAdapter.info(
      'Got participant canvas node',
      participant,
      node,
      this.remoteCanvasLayersMap,
      this.remoteCanvasLayersMap[participant.id]
    )

    node.style.setProperty('pointer-events', 'none')
    this.remoteCanvasLayersMap[participant.id] = node
    if (this.currentVideoSizeObj) {
      this.adjustCanvasesSizes(
        this.currentVideoSizeObj.videoWidth,
        this.currentVideoSizeObj.videoHeight
      )
      this.updateCanvases()
    }
  }

  setVideoNode(node) {
    if (!node || !document.body.contains(node)) {
      return
    }

    const existingNode = this.videoNode
    if (existingNode && existingNode.isSameNode(node)) {
      return
    }

    this.videoNode = node
    ConditionalLoggerAdapter.info('Received video node', this.videoNode)
    const self = this
    this.videoNode.addEventListener(
      'ec-loadmetadata',
      function () {
        if (self.videoDrawingTimeout) {
          clearTimeout(self.videoDrawingTimeout)
        }
        // delay canvases render a little so that new video stream is applied
        self.videoDrawingTimeout = setTimeout(() => {
          // if videoNode is still present in DOM
          if (document.body.contains(self.videoNode)) {
            ConditionalLoggerAdapter.info(
              'Load active stream metadata',
              self.activeStreamId,
              this.videoWidth,
              this.videoHeight
            )
            self.currentVideoSizeObj = {
              videoWidth: this.videoWidth,
              videoHeight: this.videoHeight,
            }
            self.adjustCanvasesSizes(this.videoWidth, this.videoHeight)
            self.updateCanvases()
          }
        }, 500)
      },
      false
    )
  }

  setThumbnailNode(participantId, node, type) {
    if (!node || !document.body.contains(node)) {
      return
    }
    if (!this.thumbnailsRefs[participantId]) {
      this.thumbnailsRefs[participantId] = {}
    }
    // TODO: Inherited from cleverTech, haven't investigated yet
    // eslint-disable-next-line default-case
    switch (type) {
      case 'canvas':
        // Just store the canvas reference
        ConditionalLoggerAdapter.info('Got thumbnail canvas', node, participantId)
        this.thumbnailsRefs[participantId].canvas = node
        break
      case 'video':
        ConditionalLoggerAdapter.info('Got thumbnail video', node, participantId)
        this.thumbnailsRefs[participantId].video = node
        const self = this
        // We need each thumbnail canvas to have the same inner size as their thumbnail video
        node.addEventListener(
          'ec-loadmetadata',
          function () {
            self.thumbnailsDrawingTimeouts[participantId] = setTimeout(() => {
              // if thumbnail video node is still present in DOM
              if (document.body.contains(node)) {
                ConditionalLoggerAdapter.info(
                  'Load thumbnail stream metadata',
                  participantId,
                  this.videoWidth,
                  this.videoHeight
                )
                const canvasSize = DrawCanvas.getNewCanvasesSize(this.videoWidth, this.videoHeight)
                self.thumbnailsRefs[participantId].canvas.width = canvasSize.w
                self.thumbnailsRefs[participantId].canvas.height = canvasSize.h
                self.updateThumbnailCanvas(participantId)
              }
            }, 500)
          },
          false
        )
        break
    }
  }

  /**
   * Initializes local participant and send data method (local datachannel - where we send from)
   * Called when user join the room and liveroom connection is established
   * @param publisher Publisher object
   */
  initializePublisher(publisher) {
    this.publisher = publisher
    this.sendData = publisher.sendData

    publisher.onRecordingActivated().subscribe(() => this.broadcastLocalSnapshotMap())

    publisher.onDataChannelMessage().subscribe((data) => {
      this.dataChannelMessageCallback(data)
    })
  }

  /**
   * Initializes remote participants (remote peers datachannel - where we receive from)
   * Called when user join the room and list of active liveroom participants is obtained
   * @param participants Participant object
   */
  subscribeToRemoteDataChannelEvents(participants = []) {
    ConditionalLoggerAdapter.info('Configuring remote participants', participants)

    participants.forEach((participant) => {
      const isParticipantTypeSupported = [
        ParticipantType.Remote,
        ParticipantType.Playback,
      ].includes(participant.type)

      if (!participant || !isParticipantTypeSupported || this.remoteChannels[participant.id]) {
        return
      }

      participant.onDataChannelMessage().subscribe((message) => {
        ConditionalLoggerAdapter.info('Got participant message', message)
        this.handleParticipantMessage(participant, message)
      })

      participant.onDataChannelOpened().subscribe((event) => {
        ConditionalLoggerAdapter.info('Remote datachannel connection open', participant, event)
        this.broadcastLocalSnapshotMap()
      })

      this.remoteChannels[participant.id] = participant
    })
  }

  /**
   * @function dataChannelMessageCallback is called when local participant receives a message on data channels
   * @param event {{ type, data }}
   */
  dataChannelMessageCallback(event) {
    ConditionalLoggerAdapter.info('Received data channel message from janus', event)

    if (!event.data || event.data !== 'draw_snapshot') {
      return
    }

    this.broadcastLocalSnapshotMap()
  }

  /**
   * @function localPublisherEventCallback is called when local participant successfully joins the room
   * and publishes the stream
   * @param publisher { publisher }
   */
  localPublisherEventCallback(publisher) {
    this.initializePublisher(publisher)
  }

  /**
   * @function remotePublishersEventCallback is called when local participant successfully attaches to all the
   * participants in the room
   * @param participants { participants }
   */
  remotePublishersEventCallback(participants) {
    this.subscribeToRemoteDataChannelEvents(participants)
    this.remoteParticipants = participants
  }
}
