import { useRoomFeatureFlagsRest, useUserFeatureFlag } from '../../../../../../../Common/utils/featureFlags'
import { UserFeatureFlag } from '../../../../../../../Models/apiEntities'
import { useMemo } from 'react'
import { usePermissions } from '../../../../../../../Common/hooks/usePermissions'
import useRoomPermissionsRest from '../../../../../../../Common/hooks/useRoomPermissionsRest'

export interface LiveroomDecisionInfo {
  isAvailable: boolean
  isAllowed: boolean
}

export interface NoteDecisionInfo extends LiveroomDecisionInfo {
  canDownload: boolean
}

export interface ChatDecisionInfo extends NoteDecisionInfo {
  canClear: boolean
}

export const useLiveroomDecisions = ({ room, roomInfo, currentProfile, profileApiBasedPermissions }) => {
  const { checkPermission } = usePermissions({ profileApiBasedPermissions })
  const {
    ALLOW_RECORDING,
    ALLOW_ROOM_CHAT,
    ALLOW_ROOM_CHAT_DL,
    ALLOW_ROOM_NOTES,
    ALLOW_ROOM_NOTES_DL,
    ALLOW_ROOM_DO_NOT_DISTURB,
  } = useRoomFeatureFlagsRest()
  const {
    hasRecordingPermissions,
    hasKickPermissions,
    hasMuteAllPermissions,
    hasDoNotDisturbPermissions,
    hasRoomEdittingPermissions,
    hasChatDownloadPermissions,
  } = useRoomPermissionsRest(room.id)
  const [isBrowserStreamEnabled] = useUserFeatureFlag(UserFeatureFlag.browserStream)
  const canPublish = !!roomInfo?.user?.canPublish

  const chatPermissionInfo = useMemo((): ChatDecisionInfo => {
    return {
      isAvailable: ALLOW_ROOM_CHAT,
      isAllowed: true,
      //@note: checkPermission and useRoomPermissionsRest are doing the same thing
      canClear: checkPermission({ who: currentProfile, run: 'clearMessages', on: room }),
      canDownload: ALLOW_ROOM_CHAT_DL && hasChatDownloadPermissions,
    }
  }, [ALLOW_ROOM_CHAT, ALLOW_ROOM_CHAT_DL, checkPermission, currentProfile, room, hasChatDownloadPermissions])

  const kickPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: room.creatorId === currentProfile.id || hasKickPermissions,
      isAllowed: room.creatorId === currentProfile.id || hasKickPermissions,
    }
  }, [currentProfile, room, hasKickPermissions])

  const mutePermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: room.creatorId === currentProfile.id || hasMuteAllPermissions,
      isAllowed: room.creatorId === currentProfile.id || hasMuteAllPermissions,
    }
  }, [currentProfile, room, hasMuteAllPermissions])

  const notePermissionInfo = useMemo((): NoteDecisionInfo => {
    return {
      isAvailable: ALLOW_ROOM_NOTES,
      isAllowed: true,
      canDownload: ALLOW_ROOM_NOTES_DL && room.creatorId === currentProfile.id,
    }
  }, [ALLOW_ROOM_NOTES, ALLOW_ROOM_NOTES_DL, currentProfile, room])

  // browserStream
  const browserStreamPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: isBrowserStreamEnabled && canPublish,
      isAllowed: canPublish,
    }
  }, [isBrowserStreamEnabled, canPublish])

  // screenshare
  const screensharePermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: canPublish,
      isAllowed: canPublish,
    }
  }, [canPublish])

  // room edition
  const roomEditionPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: hasRoomEdittingPermissions,
    }
  }, [hasRoomEdittingPermissions])

  const roomInvitePermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: checkPermission({ who: currentProfile, run: 'invite', on: room }),
    }
  }, [currentProfile, room, checkPermission])

  // recording
  const recordPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: ALLOW_RECORDING && hasRecordingPermissions,
      isAllowed: hasRecordingPermissions,
    }
  }, [ALLOW_RECORDING, hasRecordingPermissions])

  const appleTVPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: true,
    }
  }, [])

  const audioMixerPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: true,
    }
  }, [])

  const ebsKeysPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: true,
    }
  }, [])

  const dndPermissionInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: ALLOW_ROOM_DO_NOT_DISTURB,
      isAllowed: room.creatorId === currentProfile.id || hasDoNotDisturbPermissions,
    }
  }, [currentProfile, room, hasDoNotDisturbPermissions, ALLOW_ROOM_DO_NOT_DISTURB])

  const canPublishInfo = useMemo((): LiveroomDecisionInfo => {
    return {
      isAvailable: true,
      isAllowed: canPublish
    }
  }, [currentProfile, room, canPublish])

  return {
    recordPermissionInfo,
    chatPermissionInfo,
    notePermissionInfo,
    browserStreamPermissionInfo,
    screensharePermissionInfo,
    roomEditionPermissionInfo,
    roomInvitePermissionInfo,
    appleTVPermissionInfo,
    audioMixerPermissionInfo,
    ebsKeysPermissionInfo,
    kickPermissionInfo,
    mutePermissionInfo,
    dndPermissionInfo,
    canPublishInfo,
  }
}
