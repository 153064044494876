import { useCallback, useEffect, useMemo, useState } from 'react'
import { LiveroomParticipant } from './useLiveroomParticipants'
import { LiveroomPublisher } from './useLiveroomPublisher'
import { ParticipantType } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { logger } from '../../../../../../../Common/log/Log'
import { ActiveSpeakerDetectionControl } from './useActiveSpeakerDetection'
import { useOneTimeEffect } from '../../../../../../../Common/hooks/useOneTimeEffect'

export interface useLiveroomStreamSelectionParams {
  participants: LiveroomParticipant[]
  publisher: LiveroomPublisher
  lastJoinedParticipant: LiveroomParticipant | null
  activeSpeakerDetectionControl: ActiveSpeakerDetectionControl
  canPublish: boolean
}

export const useLiveroomStreamSelection = ({
  participants,
  publisher,
  lastJoinedParticipant,
  activeSpeakerDetectionControl: {
    currentlySpeakingParticipant,
    enableActiveSpeakerDetection,
    disableActiveSpeakerDetection,
    resetCurrentlySpeakingParticipant,
  },
  canPublish,
}: useLiveroomStreamSelectionParams) => {
  const [selectedParticipant, setSelectedParticipant] = useState<LiveroomParticipant | null>(null)
  const [isSelectedParticipantLocked, setIsSelectedParticipantLocked] = useState(false)

  // selected participant is no longer in the room
  useEffect(() => {
    if (
      selectedParticipant &&
      !participants.find((participant) => participant.id === selectedParticipant.id)
    ) {
      logger.log(`Resetting active stream.`)
      setSelectedParticipant(null)
      setIsSelectedParticipantLocked(false)
    }
  }, [participants, selectedParticipant])

  // selected participant stream was updated
  useEffect(() => {
    const updatedSelectedParticipant = participants.find(
      (participant) => participant.id === selectedParticipant?.id
    )

    if (updatedSelectedParticipant?.stream.state !== selectedParticipant?.stream.state) {
      logger.log('Selected participant stream changed, updating.')
      setSelectedParticipant(updatedSelectedParticipant!)
    }
  }, [selectedParticipant, setSelectedParticipant, participants])

  const selectPublisher = useCallback(() => setSelectedParticipant(null), [])

  // called when user clicks on thumbnail or when EBS/ScreenShare/BrowserShare joins
  const selectAndLockParticipant = useCallback((newParticipantSelection: LiveroomParticipant) => {
    logger.log('Selecting and locking participant', newParticipantSelection)
    setSelectedParticipant(newParticipantSelection)
    setIsSelectedParticipantLocked(true)
  }, [])

  // called when user clicks on already selected thumbnail
  const unselectAndUnlockParticipant = useCallback(() => {
    logger.log('Participant unselected')
    setSelectedParticipant(null)
    setIsSelectedParticipantLocked(false)
    resetCurrentlySpeakingParticipant()
  }, [resetCurrentlySpeakingParticipant])

  // called by active speaker, ie. used to programmatically change the selected participant without performing any side effects
  // like locking participant etc
  const selectParticipantById = useCallback(
    (id) => {
      const participantToSelect = participants.find((participant) => participant.id === id)
      if (participantToSelect) {
        setSelectedParticipant(participantToSelect)
      }
    },
    [participants]
  )

  // disable/enable active speaker depending whether currently selected participant is selected or not
  useEffect(() => {
    if (isSelectedParticipantLocked) {
      disableActiveSpeakerDetection()
    } else {
      enableActiveSpeakerDetection()
    }
  }, [isSelectedParticipantLocked, disableActiveSpeakerDetection, enableActiveSpeakerDetection])

  // whenever active speaker fires (currentlySpeakingParticipant changes), update selected participant
  useEffect(() => {
    if (currentlySpeakingParticipant && !isSelectedParticipantLocked) {
      selectParticipantById(currentlySpeakingParticipant.id)
    }
  }, [currentlySpeakingParticipant, isSelectedParticipantLocked, selectParticipantById])

  const active = useMemo(() => {
    if (selectedParticipant) {
      return selectedParticipant.stream
    }
    // We need to check if the publisher is an actual publisher or a viewer.
    // For viewers we try to select the first publisher available
    if (!canPublish) {
      const publishingParticipant = participants.find((p) => p.publishing)
      if (publishingParticipant) {
        setSelectedParticipant(publishingParticipant)
      }
    }
    return publisher.stream
  }, [publisher, selectedParticipant, participants])

  // selects SS/EBS/BS when it joins
  useOneTimeEffect<LiveroomParticipant>(
    {
      target: lastJoinedParticipant,
      compare: (lastRunObject) => {
        // it's a first time it's being run for lastJoinedParticipant
        if (!lastRunObject) {
          return true
        }

        // participant stream state has changed
        if (lastRunObject.stream.state !== lastJoinedParticipant?.stream.state) {
          return true
        }

        // last joined participant was updated
        return lastRunObject.id !== lastJoinedParticipant?.id
      },
      predicate: () => {
        if (!lastJoinedParticipant) {
          return false
        }

        return (
          lastJoinedParticipant.type === ParticipantType.Ebs ||
          lastJoinedParticipant.type === ParticipantType.ScreenShare ||
          lastJoinedParticipant.type === ParticipantType.BrowserStream ||
          lastJoinedParticipant.type === ParticipantType.DAMStream
        )
      },
      effect: () => {
        logger.log(`Setting ${lastJoinedParticipant!.id} as active stream.`)
        selectAndLockParticipant(lastJoinedParticipant!)
      },
    },
    [lastJoinedParticipant]
  )

  return useMemo(
    () => ({
      active,
      selectedId: selectedParticipant ? selectedParticipant.id : publisher.id,
      type: selectedParticipant?.type,
      selectPublisher,
      selectAndLockParticipant,
      unselectAndUnlockParticipant,
    }),
    [
      active,
      selectPublisher,
      selectAndLockParticipant,
      unselectAndUnlockParticipant,
      selectedParticipant,
      publisher,
    ]
  )
}
