import {
  BaseDialog,
  DefaultDialogFooter,
  DefaultDialogHeader
} from '../../../../../Components/Dialogs'
import { TextField, Typography, Box } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useRecordSessionForm } from './hooks/useRecordSessionForm'

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleStartRecording: (recordingName) => void
}

export const StartRecordingDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleStartRecording
}) => {
  const _onSubmit = useCallback(
    (recodingName) => {
      handleStartRecording(recodingName)
      handleClose()
    },
    [handleClose, handleStartRecording]
  )

  const {
    handleSubmit,
    inputs: { recordingName },
    RECORDING_NAME_MAX_LENGTH,
    recordingNameChange,
    recordingNameLength
  } = useRecordSessionForm({
    performStartRecording: _onSubmit
  })

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      testId="record-session-dialog"
      header={<DefaultDialogHeader divider={true} text="Start recording" onClose={handleClose} />}
      footer={
        <DefaultDialogFooter
          onConfirm={handleSubmit}
          confirmButtonText="Start"
          confirmButtonProps={{
            variant: 'contained',
            color: 'secondary'
          }}
        />
      }
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          id="recording-name-input"
          label="Recording name"
          fullWidth
          variant="filled"
          inputProps={{ maxLength: RECORDING_NAME_MAX_LENGTH }}
          onChange={(value) => recordingNameChange(value.target.value)}
          {...recordingName}
        />
        <Box paddingTop={2} display="flex" justifyContent="flex-end">
          <Typography
            variant="caption"
            color={
              recordingNameLength === 0 || recordingNameLength === RECORDING_NAME_MAX_LENGTH
                ? 'secondary'
                : 'textPrimary'
            }
          >
            {recordingNameLength}/{RECORDING_NAME_MAX_LENGTH}
          </Typography>
        </Box>
      </form>
    </BaseDialog>
  )
}
