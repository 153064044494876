import { ParticipantType } from '../../../Common/janus/clients/liveroom/LiveroomClient'
import { useRoom } from '../../../Providers/RoomProvider'
import {
  StreamSelectionControl,
} from '../../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroom'
import { useSession } from '../../../Views/MainView/hooks/useSession'
import { turnOffWatermark, turnOnWatermark } from '../toggleWatermark'

interface UseWatermarkProps {
  stream: StreamSelectionControl
}

const styles: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  padding: '4.5rem',
  fontWeight: 700,
  fontSize: '1.8rem',
  color: '#ffffff',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textShadow:
    '1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black, -1px 1px 0 black, 2px 2px 5px rgb(0 0 0 / 25%)',
  display: 'none',
  pointerEvents: 'none',
  opacity: 0.6,
}

// These options are just Jake's best attempt at keeping the date short,
// but also making sure the month/day don't get confused, the AM/PM doesn't
// get confused and the timezone is always visible
const dateOptions = {
  hourCycle: 'h12',
  timeZoneName: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const useWatermark = (props: UseWatermarkProps) => {
  const { stream } = props
  const { userProfile } = useSession()
  const room = useRoom()
  // just leave the function if watermarking is not turned on
  if (room && room.featureFlagsRest && room.featureFlagsRest?.ALLOW_WATERMARK && room.featureFlagsRest?.ALLOW_WATERMARK.value !== 'true') return

  const date = new Date()
  const dateString = new Intl.DateTimeFormat(
    'en-US',
    dateOptions as Intl.DateTimeFormatOptions
  ).format(date)

  const email = userProfile?.email
  // once we have the email, we have the most important part of data. The rest is helping identify if there's not an email for whatever reason
  const displayName = userProfile?.displayName === email ? null : userProfile?.displayName
  const roomName = email ? null : room.displayName

  const info = [email, displayName, roomName].filter(Boolean)

  if (
    stream.type === ParticipantType.Ebs ||
    stream.type === ParticipantType.BrowserStream ||
    stream.type === ParticipantType.Playback ||
    stream.type === ParticipantType.DAMStream
  ) {
    turnOnWatermark(info, dateString, styles)
  } else {
    turnOffWatermark(styles)
  }
}
