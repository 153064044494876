import React from 'react'

import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'

import { BaseDialog, DefaultDialogHeader } from '../../../../../../Components/Dialogs'
import EBSKey from '../EBSKeys'

import { useStreamKey } from '../hooks/useStreamKey'

interface EBSKeysDialogProps {
  isOpen?: boolean
  classNames?: string
  handleClose?: () => void
  room: {
    id: string
  }
}

/**
 * Dialog wrapper to show EBS keys info
 * @param isOpened - Flag that indicates if the dialog is visible or not
 * @param handleClose - function that handles when the close action is triggered
 * @param room - the room which is going to be used to stream EBS
 * @constructor
 */
const EBSKeysDialog: React.FC<EBSKeysDialogProps> = ({ isOpen, handleClose, room }) => {
  const streamKeyOptions = useStreamKey()

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="EBS stream keys" onClose={handleClose} divider />}
    >
      <EBSKey roomId={room.id} streamKeyOptions={streamKeyOptions} />
    </BaseDialog>
  )
}

export default createFragmentContainer(EBSKeysDialog, {
  room: graphql`
    fragment EBSKeysDialog_room on Liveroom {
      id
    }
  `
})
