import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  PlaybackClient,
  PlaybackParticipant
} from '../../../../../../Common/janus/clients/playback/PlaybackClient'
import { streamFrom, UnavailableStream } from '../../../../../../Common/media/stream'
import { LiveroomParticipant } from '../../../RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'

interface DrawCanvasControl {
  activeStreamId: string | null
  participants: PlaybackParticipant[]
}

interface usePlaybackParticipantsParams {
  playbackClient: PlaybackClient | null
}

export const usePlaybackParticipants = ({ playbackClient }: usePlaybackParticipantsParams) => {
  const [participants, setParticipants] = useState<PlaybackParticipant[]>([])
  const [streams, setStreams] = useState<{ [key: string]: MediaStream | null }>({})
  const [selectedParticipantId, setSelectedParticipantId] = useState<string | null>(null)
  const [activeStreams, setActiveStreams] = useState<Set<string>>(() => new Set())

  useEffect(() => {
    if (playbackClient) {
      playbackClient.onPlaybackReady().subscribe(({ participants, controller }) => {
        setParticipants(participants)
        participants.forEach((participant) => {
          participant.onRemoteStream().subscribe((mediaStream) => {
            setStreams((streams) => ({ ...streams, [participant.id]: mediaStream }))
          })
        })
        controller
          .onActiveStreams()
          .subscribe((activeStreams) => setActiveStreams(new Set(activeStreams)))
      })
    } else {
      setParticipants([])
      setStreams({})
    }
  }, [playbackClient])

  const toModel = useCallback(
    (participant: PlaybackParticipant) => {
      const stream = activeStreams.has(participant.id)
        ? streamFrom({
            mediaStream: streams[participant.id],
            hasAudioTrack: true,
            hasVideoTrack: true,
            speaker: null
          })
        : UnavailableStream
      return {
        id: participant.id,
        displayName: participant.displayName,
        stream,
        type: participant.type
      } as LiveroomParticipant
    },
    [streams, activeStreams]
  )

  useEffect(() => {
    if (!selectedParticipantId || !activeStreams.has(selectedParticipantId)) {
      if (activeStreams.size > 0) {
        setSelectedParticipantId(activeStreams.values().next().value)
      }
    }
  }, [activeStreams, selectedParticipantId, participants])

  const participantsModel = useMemo(() => participants.map(toModel), [participants, toModel])

  const selectParticipant = useCallback(
    (participant: LiveroomParticipant) => setSelectedParticipantId(participant.id),
    []
  )

  const activeStream = useMemo(() => {
    const activeParticipant = participantsModel.find(
      (participant) => participant.id === selectedParticipantId
    )
    if (activeParticipant) {
      return activeParticipant.stream
    }
    return UnavailableStream
  }, [participantsModel, selectedParticipantId])

  const drawCanvas: DrawCanvasControl = useMemo(
    () => ({
      activeStreamId: selectedParticipantId,
      participants
    }),
    [participants, selectedParticipantId]
  )

  return {
    selectParticipant,
    participants: participantsModel,
    activeStream,
    drawCanvas,
    selectedParticipantId
  }
}
