import { useEffect, useState } from 'react'
import axios from 'axios'
import { useEffectWithPredicate } from './useEffectWithPredicate'
import useApi from '../../API/useApi'
import { useRoom } from '../../Providers/RoomProvider'


export const useWebSocketServerUrl = () => {
    const [webSocketServerUrl, setWebSocketServerUrl] = useState<string | null>(null)

    const room = useRoom()
    const { Room } = useApi()

    const getWebSocketServerURL = async () => {
        try {
            let response = await Room.getRoomServerURL(room.id)

            if (!response.webrtcWSUrl) {
                console.error('Unable to get the server URLs')
                return null
            }

            if (response.authEndpoint) {
                if(!response.accessToken) {
                    console.error('Unable to get the server access token')
                    return null
                }
                await axios.post( response.authEndpoint, {accessToken: response.accessToken}, {withCredentials: true})
            }

            return response.webrtcWSUrl
        } catch (e) {
            console.error(e.message)
            return null
        }
    }

    const getWebSocketServerURLWithRetry = async () => {
        let url: string | null = null
        let backoff = 1000
        while (!url) {
            url = await getWebSocketServerURL()
            if (!url) {
                await new Promise(r => setTimeout(r, backoff));
                backoff = backoff * 2
            }
        }
        return url
    }

    useEffect(() => {
      setWebSocketServerUrl(null)
      }, [room.id]
    )

    useEffectWithPredicate(
        {
            predicate: () => webSocketServerUrl === null,
            effect: () => {
                getWebSocketServerURLWithRetry().then((newUrl) => {
                    setWebSocketServerUrl(newUrl)
                })
            }
        },
        [webSocketServerUrl]
    )

    return webSocketServerUrl
}
