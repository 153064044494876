export enum CanvasNodeType {
  Main = 'main',
  Buffer = 'buffer'
}

export enum ThumbnailNodeType {
  Canvas = 'canvas',
  Video = 'video'
}

export interface IDrawCanvas {
  setVideoNode: (node: HTMLVideoElement | null) => void
  setThumbnailNode: (participantId, node, type: ThumbnailNodeType) => void
  setLocalCanvasNode: (node: HTMLDivElement | null, type: CanvasNodeType) => void
  setParticipantCanvasNode: (participant, index, node) => void
}
