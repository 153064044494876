import {useState, useEffect} from 'react'
import {DamClient} from "./DamClient";
import {useEffectWithPredicate} from "../hooks/useEffectWithPredicate";
import axios from "axios";
import {useEnvironment} from "../../Providers/EnvironmentProvider";
import {LiveroomState} from "../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomClient";
import {useRoom} from "../../Providers/RoomProvider";

interface useDamClientParams {
    state: LiveroomState
}

export const useDamClient = ({state}: useDamClientParams) => {
    const [damClient, setDamClient] = useState<DamClient | null>(null)

    const restApiUrl = useEnvironment().restApiUrl

    const room = useRoom()
    // For now, the sidecar only supports box, so it only needs to consider the flag related to that integration
    const shouldConnectToDamSidecar = room?.featureFlagsRest?.ALLOW_INTEGRATION_BOX?.value === 'true'

    const createDamClient = async () => {
        try {
            let response = await axios.post(`${restApiUrl}/api/rooms/${room.id}/sidecar/dam`, {})

            const damSidecarUrls = response.data.data
            if(!damSidecarUrls) {
                throw new Error('Unable to get the sidecar URLs');
            }

            response = await axios.get( `${restApiUrl}/api/rooms/${room.id}/sidecar/authorization`)

            const damSidecarToken = response.data.data.token
            if(!damSidecarToken) {
                throw new Error('Unable to get the sidecar token');
            }

            await axios.post(`${damSidecarUrls.HttpUrl}/config/token`, {token: damSidecarToken}, {withCredentials: true})

            const playbackWebsocket = new WebSocket(damSidecarUrls.WssUrl)
            playbackWebsocket.onclose = (_closeEvent) => {
                setDamClient(null);
            }
            return new DamClient(damSidecarUrls.HttpUrl, playbackWebsocket)
        } catch (e) {
            console.error(e.message)
            return null
        }
    }

    const createDamClientWithRetry = async () => {
        let damClient: DamClient | null = null
        let backoff = 1000
        while (!damClient) {
            damClient = await createDamClient()
            if (!damClient) {
                await new Promise(r => setTimeout(r, backoff));
                backoff = backoff * 2
            }
        }

        return damClient
    }

    useEffect(() => {
            if (damClient && state !== LiveroomState.joined) {
                setDamClient(null)
            }
        },
        [damClient, state]
    )

    useEffectWithPredicate(
        {
            predicate: () => shouldConnectToDamSidecar && damClient === null && state === LiveroomState.joined,
            effect: () => {
                createDamClientWithRetry().then((newClient) => {
                    setDamClient(newClient)
                })
            }
        },
        [damClient, state]
    )

    return {damClient}
}
