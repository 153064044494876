import React from 'react'
import { Divider, MenuList } from '@material-ui/core'

import Draw from './MenuItems/Draw/Draw'
import Shapes from './MenuItems/Shapes/Shapes'
import Text from './MenuItems/Text/Text'
import Color from './MenuItems/Color/Color'
import Stroke from './MenuItems/Stroke/Stroke'
import Eraser from './MenuItems/Eraser/Eraser'
import ClearAll from './MenuItems/ClearAll/ClearAll'

/**
 * Menu - side drawer menu
 * @constructor
 */
export const Menu: React.FC = () => {
  return (
    <MenuList data-testid="side-drawer-menu">
      <Draw />
      <Shapes />
      <Text />
      <Stroke />
      <Color />
      <Eraser />
      <ClearAll />
      <Divider />
    </MenuList>
  )
}

export default React.memo(Menu)
