/* 
BEFORE EDITING THIS FILE

This is used both here and in the admin portal
They should be kept in sync
*/

import { useQuery } from 'react-query'
import useApi from '../../API/useApi'

export enum PermissionType {
  ADMIN_PORTAL_VIEW = 'adminportal:view',
  ROOM_ASSIGN = 'room:assign',
  ROOM_CREATE = 'room:create',
  ROOM_DELETE = 'room:delete',
  ROOM_UPDATE = 'room:update',
  ROOM_CHAT_DELETE = 'room:chat:delete',
  ROOM_CHAT_DOWNLOAD = 'room:chat:download',
  ROOM_CHAT_LIST = 'room:chat:list',
  ROOM_CHAT_write = 'room:chat:write',
  ROOM_INVITE_CREATE = 'room:invite:create',
  ROOM_INVITE_DELETE = 'room:invite:delete',
  ROOM_INVITE_LIST = 'room:invite:list',
  ROOM_NOTE_WRITE = 'room:note:write',
  ROOM_NOTE_DELETE = 'room:note:delete',
  ROOM_NOTE_DOWNLOAD = 'room:note:download',
  ROOM_NOTE_LIST = 'room:note:list',
  SESSION_CREATE = 'room:session:create',
  SESSION_DELETE = 'room:session:delete',
  SESSION_LIST = 'room:session:list',
  SESSION_WATCH = 'room:session:watch',
  MUTE_ALL = 'room:mute_all',
  KICK = 'room:kick',
}

const usePermissions = () => {
  const { Permission } = useApi()

  const { data: permissionsObject } = useQuery('get-permissions', () =>
    Permission.getUserPermissions()
  )

  const hasAdminPortalAccess = (permissionList) => {
    if (!permissionList) {
      return false
    }

    return permissionList[PermissionType.ADMIN_PORTAL_VIEW]?.organizations?.length > 0
  }

  const canSeeAdminPortal = hasAdminPortalAccess(permissionsObject)

  const authorize = (object, permission) => {
    if (!permissionsObject || !Object.keys(permissionsObject).includes(permission)) {
      return false
    }

    // Super admins have permissions to all organizations
    if (permissionsObject[permission]?.organizations === '*') {
      return true
    }

    const permissionPrefix = permission.split(':')[0]

    switch (permissionPrefix) {
      case 'room':
        return !!(
          permissionsObject[permission].rooms?.includes(object.id) ||
          permissionsObject[permission].organizations?.includes(object.organizationId)
        )
      case 'org':
        return !!permissionsObject[permission].organizations?.includes(object.organizationId)
      default:
        return false
    }
  }

  return {
    canSeeAdminPortal,
    authorize,
  }
}

export default usePermissions
