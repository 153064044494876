import React from 'react'
import { Grid } from '@material-ui/core'
import { Room, RoomSession } from '../../../../../Models/apiEntities'
import RecordingCard from './RecordingCard/RecordingCard'
import Divider from '@material-ui/core/Divider'
import VideocamIcon from '@material-ui/icons/Videocam'
import ChatIcon from '@material-ui/icons/Chat'
import Box from '@material-ui/core/Box'
import { useRecordingsTab } from './hooks/useRecordingsTab'

import './RecordingsTab.scss'
import Typography from '@material-ui/core/Typography'
import { PlaybackChatContainer } from '../Chat/PlaybackChatContainer'

interface RecordingsTabProps {
  currentProfile: any
  room: Room
  selectedSession: RoomSession | null
  onSessionSelected: (newSession: RoomSession) => void
}

/**
 * Recordings tab and messages
 * @param currentProfile
 * @param room room information
 * @param selectedSession
 * @param onSessionSelected
 * @constructor
 */
export const RecordingsTab: React.FC<RecordingsTabProps> = ({
  currentProfile,
  room,
  selectedSession,
  onSessionSelected
}) => {
  const isOwner = room.creatorId === currentProfile.id
  const { recordingsOption, handleRecordingsOptionChange } = useRecordingsTab()

  return (
    <Box className="recordings-tab" display="flex" flexDirection="column" maxHeight="100%">
      <Box pt={2} pb={1} pr={2}>
        <Grid
          className="recordings-tab-actions"
          container
          justify="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <div
              className="recordings-tab-action"
              onClick={() => handleRecordingsOptionChange(true)}
              data-testid="recordings-option"
            >
              <VideocamIcon color={recordingsOption ? 'secondary' : 'inherit'} />
            </div>
          </Grid>
          <Grid item>
            <div
              className="recordings-tab-action"
              onClick={() => handleRecordingsOptionChange(false)}
              data-testid="messages-option"
            >
              <ChatIcon color={recordingsOption ? 'inherit' : 'secondary'} />
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box
        padding={recordingsOption ? 2 : 0}
        className="recordings-tab-content"
        overflow="auto"
        flexGrow={1}
      >
        {recordingsOption ? (
          <>
            {room.sessions && room.sessions.nodes.length > 0 ? (
              <Grid container spacing={2}>
                {room.sessions.nodes
                  .filter((session) => session.deletedAt === null)
                  .map((session) => (
                    <Grid key={session.id} item xs={12} sm={6}>
                      <RecordingCard
                        isActive={!!selectedSession && selectedSession.id === session.id}
                        session={session}
                        room={room}
                        isOwner={isOwner}
                        onSelected={() => onSessionSelected(session)}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Box pt={6} className="recordings-tab-content-no-items">
                <Typography
                  variant="body1"
                  color="textPrimary"
                >{`You currently have no saved recordings.`}</Typography>
              </Box>
            )}
          </>
        ) : (
          <PlaybackChatContainer sessionId={selectedSession?.id} />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(RecordingsTab)
