import { useCallback, useEffect, useState } from 'react'
import { Publisher } from '../../../Common/janus/clients/liveroom/LiveroomClient'
import {
  formatStats,
  getStatsForDisplay,
} from '../../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useClientsideDiagnostics'
import {
  LiveroomParticipant
} from '../../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'
import { TransportStats, WebRTCStatsParser } from '../../../Common/janus/stats/WebRTCStatsParser'
import Bowser from 'bowser'

export interface RTCConnectivityStats {
  // I'm using this to be a more explicit interface, it's based and derived from RTCStatsReport
  uploadByteRate: number
  downloadByteRate: number
  rtt: number
  bytesSent: number
  bytesReceived: number
  timestamp: number
  jitter: number
  uploadBitRate?: number
  downloadBitRate?: number
  platform?: string
  platformVersion?: string
  operatingSystem?: string
}

interface ParticipantInboundStats {
  prevBytes: number
  prevTimestamp: number
  bitrate: number
}
export interface ParticipantInboundStatsMap {
  [pairId: string] : ParticipantInboundStats
}

const RTCParticipantInboundStats: ParticipantInboundStatsMap = {}
export const useGetRTCConnectivity = (publisher: Publisher, participants: LiveroomParticipant[], shouldTurnOnInterval: boolean) => {
  const [currentRTCConnectivityStats, setStats] = useState<RTCConnectivityStats>()
  const getRTCConnectivity = useCallback(
    async ({
      timestamp: prevTimestamp,
      bytesSent: prevBytesSent,
      bytesReceived: prevBytesReceived,
    }: RTCConnectivityStats) => {
      if (!publisher) return
      const stats = await publisher.getMediaStreamStats()
      if (!stats) return

      const payload = formatStats(stats)

      const {
        bytesSent,
        bytesReceived,
        responsesReceived,
        totalRoundTripTime,
        timestamp,
        jitter,
      } = getStatsForDisplay(payload as RTCStatsReport)
      const elapsedTime = timestamp - prevTimestamp
      const uploadByteRate = (bytesSent - prevBytesSent) / (elapsedTime / 1000)
      let downloadByteRate = (bytesReceived - prevBytesReceived) / (elapsedTime / 1000)
      const rtt = (totalRoundTripTime / responsesReceived) * 1000
      for (const participant of participants) {
        if (participant && participant.getInboundStats) {
          const stats = await participant.getInboundStats()
          let { selectedCandidatePairId, bytesReceived } = WebRTCStatsParser.getTransportStats(stats as RTCStatsReport) as TransportStats
          
          if (selectedCandidatePairId) {
            const bytesReceivedNow = bytesReceived || 0
            const timestamp = (new Date().getTime())

            if (selectedCandidatePairId in RTCParticipantInboundStats) {
              const bytesReceivedBefore = RTCParticipantInboundStats[selectedCandidatePairId].prevBytes
              const timeDiff = timestamp - RTCParticipantInboundStats[selectedCandidatePairId].prevTimestamp
              if (timeDiff > 0) {
                RTCParticipantInboundStats[selectedCandidatePairId].bitrate = (bytesReceivedNow - bytesReceivedBefore) / (timeDiff / 1000)
              }
            } else {
              RTCParticipantInboundStats[selectedCandidatePairId] = {
                bitrate: 0,
                prevTimestamp: 0,
                prevBytes: 0
              }
            }
            
            RTCParticipantInboundStats[selectedCandidatePairId].prevTimestamp = timestamp
            RTCParticipantInboundStats[selectedCandidatePairId].prevBytes = bytesReceivedNow
          }
        }
      }
  
      Object.keys(RTCParticipantInboundStats).forEach(key => {
        downloadByteRate += RTCParticipantInboundStats[key].bitrate
      })

      const bowserParser = Bowser.getParser(window.navigator.userAgent)

      return {
        uploadByteRate,
        downloadByteRate,
        rtt,
        timestamp,
        bytesSent,
        bytesReceived,
        jitter,
        platform: "WebApp",
        operatingSystem: bowserParser.getOSName(),
      }
    },
    [publisher, participants]
  )

  useEffect(() => {
    if (!shouldTurnOnInterval) return
    let isOnFirstRun = true
    let connectivityStats: RTCConnectivityStats = {
      uploadByteRate: 0,
      downloadByteRate: 0,
      rtt: 0,
      bytesSent: 0,
      bytesReceived: 0,
      timestamp: new Date().getTime(),
      jitter: 0,
    }
    const int = setInterval(
      () =>
        getRTCConnectivity(connectivityStats).then((result) => {
          if (result) connectivityStats = result
          // The data won't be right the first time because we set everything to 0
          if (!isOnFirstRun) setStats(connectivityStats)
          isOnFirstRun = false
        }),
      1000
    )
    return () => clearInterval(int)
  }, [getRTCConnectivity, shouldTurnOnInterval])

  return { getRTCConnectivity, currentRTCConnectivityStats }
}
