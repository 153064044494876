import React from 'react'
import { Box } from '@material-ui/core'

import SideDrawer from '../../../../../../../Components/SideDrawer/SideDrawer'
import SideDrawerContent from '../../../../../../../Components/SideDrawer/SideDrawerContent'
import Menu from './Menu/Menu'
import { useDrawCanvasToolsContext } from '../../../../../../../Providers/DrawCanvasToolsProvider'
import { Icons } from './DrawToolIcon/DrawToolIcon'

interface LiveRoomDrawerProps {
  onClickCloseBtn: () => void
}

/**
 * LiveRoomDrawer
 * @param onClickCloseBtn - action that will be triggered when the user clicks the close side bar button
 * @constructor
 */
export const LiveRoomDrawer: React.FC<LiveRoomDrawerProps> = ({ onClickCloseBtn }) => {
  const {
    drawPreferences: { drawTool }
  } = useDrawCanvasToolsContext()

  return (
    <Box width="100%" height="100%">
      <SideDrawer onClickCloseBtn={onClickCloseBtn} title="Draw Tool" pinnedIcon={Icons[drawTool]}>
        <SideDrawerContent>
          <Menu />
        </SideDrawerContent>
      </SideDrawer>
    </Box>
  )
}

export default React.memo(LiveRoomDrawer)
