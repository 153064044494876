import { useCallback, useState, useEffect } from 'react'

import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useCreateStreamKeyMutation, useGetStreamKeyMutation } from './useStreamKeyMutation'
import { logger } from '../../../../../../Common/log/Log'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'

export interface StreamKeyOptions {
  performAction: () => void
  streamKeyActionText: string
  streamKey?: string
  isLoading: boolean
  isVisible: boolean
}

const defaultErrorText = 'Error when creating/getting a stream key!'

/**
 * custom hook to manage stream keys used in EBS
 * @constructor
 */
export const useStreamKey = (): StreamKeyOptions => {
  const alert = useAlerts()
  const [
    executeCreateMutation,
    { isInProgress: isCreateInProgress, error: errorCreate, graphQLErrors: graphQLErrorsCreate }
  ] = useCreateStreamKeyMutation()
  const [
    executeGetMutation,
    { isInProgress: isGetInProgress, graphQLErrors: graphQLErrorsGet }
  ] = useGetStreamKeyMutation()

  const [streamKey, setIsStreamKey] = useState()
  const [isVisible, setIsVisible] = useState(false)

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors: graphQLErrorsCreate || graphQLErrorsGet,
    defaultErrorText
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [errorCreate])

  useEffect(() => {
    const getStreamKeyFunc = performStreamKeyAction(getStreamKey)
    getStreamKeyFunc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showStreamKey = useCallback(() => {
    setIsVisible(true)
  }, [setIsVisible])

  const performStreamKeyAction = useCallback(
    (mutationFunc) => {
      return async () => {
        try {
          const result = await mutationFunc()
          setIsStreamKey(result)
        } catch (e) {
          logger.error(e)
        }
      }
    },
    [setIsStreamKey]
  )

  const createStreamKey = useCallback(async () => {
    const result = await executeCreateMutation({ input: {} })
    return result?.createStreamKey?.uuid
  }, [executeCreateMutation])

  const getStreamKey = useCallback(async () => {
    const result = await executeGetMutation({ input: {} })
    return result?.getStreamKey?.uuid
  }, [executeGetMutation])

  return {
    performAction: !isVisible ? showStreamKey : performStreamKeyAction(createStreamKey),
    streamKeyActionText: streamKey
      ? isVisible
        ? 'Refresh stream key'
        : 'Show stream key'
      : 'Create stream key',
    streamKey,
    isLoading: isCreateInProgress || isGetInProgress,
    isVisible
  }
}
