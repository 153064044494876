import React from 'react'
import QueryRenderer from '../../../../GraphQL/QueryRenderer'
import RecordingsViewQuery from '../../../../GraphQL/queries/RecordingsViewQuery'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useRecordings } from './hooks/useRecordings'
import RecordingsTab from './RecordingsTab/RecordingsTab'

import './RecordingsView.scss'
import UnderConstruction from '../../../../Components/UnderConstruction/UnderConstruction'
import Box from '@material-ui/core/Box'
import Playback from './Playback/Playback'
import VideocamIcon from '@material-ui/icons/Videocam'
import Typography from '@material-ui/core/Typography'
import { Room, UserProfile } from '../../../../Models/apiEntities'
import { useParams } from 'react-router-dom'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { ShowProgress } from '../../../../Providers/ProgressProvider'
import HorizontalLayout from '../../../../Components/HorizontalLayout/HorizontalLayout'
import SlideAndPush from '../../../../Components/SlideAndPush/SlideAndPush'
import { Animations } from '../../../../Common/constants/animations'
import { useDisplayingFormat } from '../../../../Common/hooks/useDisplayingFormat'
import { RoomProvider } from '../../../../Providers/RoomProvider'
import NotFoundRedirect from '../../NotFoundView/NotFoundRedirect'
import {
  RoomErrorState,
  RoomState,
  useRoomRoutingState,
} from '../RoomView/Room/hooks/useRoomRoutingState'
import PrivateRoomMFA from '../RoomView/PrivateRoomMFA/PrivateRoomMFA'
import RoomAccessDenied from '../RoomView/RoomAccessDenied/RoomAccessDenied'
import RoomNotFound from '../RoomView/RoomNotFound/RoomNotFound'
import RoomDeleted from '../RoomView/RoomDeleted/RoomDeleted'
import RoomInviteUsed from '../RoomView/RoomInviteUsed/RoomInviteUsed'
import RoomInvalidInvite from '../RoomView/RoomInvalidInvite/RoomInvalidInvite'
import PrivateRoomMissingMFA from '../RoomView/PrivateRoomMissingMFA/PrivateRoomMissingMFA'

const tabsValues = {
  recordings: 'recordings',
  clips: 'clips',
}

interface RecordingsViewProps {
  currentProfile: UserProfile
  room: Room
  iceServers
  retry
}

interface ErrorComponentProps {
  errorState: string
}

const ErrorComponent = ({ errorState }: ErrorComponentProps) => {
  const RoomErrorStateComponents = {
    [RoomErrorState.AccessDenied]: RoomAccessDenied,
    [RoomErrorState.NotFound]: RoomNotFound,
    [RoomErrorState.Deleted]: RoomDeleted,
    [RoomErrorState.InviteUsed]: RoomInviteUsed,
    [RoomErrorState.InviteInvalid]: RoomInvalidInvite,
    [RoomErrorState.PrivateRoomMissingMFA]: PrivateRoomMissingMFA,
  }

  return RoomErrorStateComponents[errorState] || <></>
}

/**
 * Room recordings
 * @constructor
 */
const RecordingsView: React.FC<RecordingsViewProps> = ({
  currentProfile,
  room,
  iceServers,
  retry,
}) => {
  const {
    isNotesOpen,
    isAudioActive,
    session,
    selectedTab,
    actions: { selectSession, selectTab, handleNotesClick, handleAudioClick },
  } = useRecordings({ room })

  const { isLargeDesktop, isDesktop } = useDisplayingFormat()
  const tabsWidth = isLargeDesktop ? 480 : isDesktop ? 380 : 320

  // verify MFA before showing recordings view on dashboard if room is MFA protected
  const { roomRoutingState, errorState, verifyMFA } = useRoomRoutingState({
    retry,
    room,
  })

  if (errorState !== null) {
    return <ErrorComponent errorState={String(errorState)} />
  }

  if (roomRoutingState === RoomState.Fetching) {
    return <ShowProgress />
  }

  if (roomRoutingState === RoomState.MFAInput) {
    return <PrivateRoomMFA verifyMFA={verifyMFA}  />
  }

  return (
    <RoomProvider room={room}>
      <HorizontalLayout
        height="calc(100vh - 80px)"
        width="100vw"
        overflow="hidden"
        className="recordings-view"
        position="relative"
      >
        {session !== null && (
          <Playback
            key={session.id}
            session={session}
            iceServers={iceServers}
            isNotesOpen={isNotesOpen}
            onNotesClick={handleNotesClick}
            isAudioActive={isAudioActive}
            onAudioClicked={handleAudioClick}
          />
        )}
        {session === null && (
          <Grid container direction="column" justify="center" alignItems="center">
            <VideocamIcon className="recordings-view-icon" />
            <Box marginTop={2}>
              <Typography variant="h6">Please select a recording to play.</Typography>
            </Box>
          </Grid>
        )}
        <SlideAndPush
          isOpen={isNotesOpen || session == null}
          width={tabsWidth}
          height="100%"
          from="right"
          position="absolute"
          zIndex={2}
          timeout={Animations.fastest}
        >
          <Box
            className="recordings-view-tabs"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Box height={72}>
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => selectTab(newValue)}
                aria-label="recordings-tabs"
                variant="fullWidth"
              >
                <Tab label="Recordings" value={tabsValues.recordings} />
                <Tab label="Clips" value={tabsValues.clips} />
              </Tabs>
            </Box>
            <Box flexGrow={1} overflow="hidden">
              {selectedTab === tabsValues.recordings && (
                <RecordingsTab
                  room={room}
                  currentProfile={currentProfile}
                  selectedSession={session}
                  onSessionSelected={selectSession}
                />
              )}
              {selectedTab === tabsValues.clips && (
                <Box pt={5}>
                  <UnderConstruction />
                </Box>
              )}
            </Box>
          </Box>
        </SlideAndPush>
      </HorizontalLayout>
    </RoomProvider>
  )
}

const RecordingViewFragmentContainer = createFragmentContainer(RecordingsView, {
  currentProfile: graphql`
    fragment RecordingsView_currentProfile on Profile {
      id
    }
  `,
  room: graphql`
    fragment RecordingsView_room on Liveroom {
      sessions: sessionsByRoomId(orderBy: [CREATED_AT_DESC], condition: { deletedAt: null }) {
        nodes {
          id
          recordingId
          displayName
          startedAt
          stoppedAt
          createdAt
          deletedAt
        }
      }
      id
      creatorId
      organizationId
      hash
      featureFlags {
        nodes {
          key
          value
        }
      }
    }
  `,
  iceServers: graphql`
    fragment RecordingsView_iceServers on IceServer @relay(plural: true) {
      ...Playback_iceServers
    }
  `,
})

const RecordingsViewWithQuery: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>()
  return (
    <QueryRenderer
      query={RecordingsViewQuery}
      variables={{ roomId }}
      renderLoading={() => <ShowProgress />}
      renderResult={({ room, currentProfile, iceServers }, retry) =>
        room ? (
          <RecordingViewFragmentContainer
            room={room}
            currentProfile={currentProfile}
            iceServers={iceServers}
            {...retry}
          />
        ) : (
          <NotFoundRedirect />
        )
      }
    />
  )
}

export default React.memo(RecordingsViewWithQuery)
